import React, { FC, useState } from 'react';
import './account.scss';
import IndexPage from '..';
import exitVector from './assets/exitVector.webp';
import loginVector from './assets/login.webp';
import emailVactor from './assets/email..webp';
import registrVector from './assets/registration.webp';
import lastEntryVector from './assets/lastEntry.webp';
import userVector from './assets/user.webp';
import AddAccount from '../../elements/add-account/AddAccount';
import plusVector from './assets/plus.webp'
import {removeToken} from "../../app/api/api.helpres";
import Button from '../../components/button';


const Account:FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <IndexPage title={"Account"} top_children={
            <div className="account-page__top-button">
                <Button image={plusVector} onClick={() => setIsOpen(!isOpen)}>Add new account</Button>
            </div>
        }>
            <div className="account-page__content">
                <div className="account-page__status">Administrator</div>
                <div className="account-page__user">
                    <div className="account-page__user-img"><img src={userVector} alt='user' /></div>
                    <div className="account-page__user-name">Alex Smith</div>
                    <div className="account-page__user-status">Administrator</div>
                </div>
                <div className="account-page__data">
                    <div className="account-page__data-item">
                        <img src={loginVector} alt='login' />
                        <div className='account-info__title'>Login:</div>
                        <div className='account-info__content'>Erisd_432</div>
                    </div>
                    <div className="account-page__data-item">
                        <img src={emailVactor} alt='email'/>
                        <div className='account-info__title'>Email:</div>
                        <div className='account-info__content'>mailwad@gmail.com</div>
                    </div>
                </div>
                <div className="account-page__data">
                    <div className="account-page__data-item">
                        <img src={registrVector} alt='registration'/>
                        <div className='account-info__title'>Registration:</div>
                        <div className='account-info__content'>31.07.2022</div>
                    </div>
                    <div className="account-page__data-item">
                        <img src={lastEntryVector} alt='last entry'/>
                        <div className='account-info__title'>Last entry:</div>
                        <div className='account-info__content'>31.07.2023</div>
                    </div>
                </div>
                <div className="account-page__exit">
                    <button className='account-page__exit-btn' onClick={() => removeToken()}>
                        <img src={exitVector} alt='exit'/> Exit
                    </button>
                </div>
            </div>
            {isOpen ? (
                <div className='add-account-page'>
                    <AddAccount setIsOpen={setIsOpen} isOpen={isOpen} />
                </div>
            ) : null
            }
        </IndexPage>
    );
};

export default Account;
