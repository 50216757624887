import TicketType from "../api/tickets/tickets.types"
import axiosInstance from "../api/axiosInstance"
import Tokens from "../api/auth/auth.typing";
import PaginationTypes from "../../components/pagination/pagination.types";
import Cookies from "js-cookie";

const Service = {
    async getTickets(pagination: PaginationTypes.Paginate, form: FormData){
        return await axiosInstance.post<TicketType.DataResponses>(`ticket/tickets_all/?page=${pagination.page}&size=${pagination.size}`, form)
    },
    async postLogin(form: FormData) {
        return await axiosInstance.post<Tokens.AllTokens>('auth/login', form)
    },
    async postRegister(form: FormData) {
        return await axiosInstance.post('auth/register', form)
    },
    async postRefreshAccessToken() {
        
        const refreshToken = Cookies.get('refreshToken')
        const headers = {Authorization: `Bearer ${refreshToken}`}
        return await axiosInstance.post<Tokens.AccessToken>('auth/refresh', undefined, {headers: headers})
    }
}
export default Service;
