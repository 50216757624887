import { QueryClientProvider as TanStackQueryClientProvider } from '@tanstack/react-query'
import { QueryClient } from '@tanstack/react-query'
import React from 'react'
import { FC, ReactNode } from 'react'

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
})

type QueryClientProviderProps = {
	children: ReactNode
}

export const QueryClientProvider: FC<QueryClientProviderProps> = ({
	children
}) => {
	return (
		<TanStackQueryClientProvider client={queryClient}>
			{children}
		</TanStackQueryClientProvider>
	)
}
