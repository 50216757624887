import IndexPage from '..'
import clsx from 'clsx'
import { useAlertMessage } from 'hooks/useMessageAlert'
import React, { FC, useEffect, useRef, useState } from 'react'

import { AgentTypes } from '../../app/api/agents/agents.types'
import { FilterJSX } from '../../app/assets'
import { TicketsService } from '../../app/service/tIckets.service'
import Button from '../../components/button'
import Pagination from '../../components/pagination/Pagination'
import PaginationTypes from '../../components/pagination/pagination.types'
import { AgentsTable } from '../../elements/agents/agents-table'
import {
	useCreateAgent,
	useEditAgent,
	useUploadFile
} from '../../elements/agents/api'
import { AgentsFileModal } from '../../elements/agents/modal/agents-file'
import { AgentsFilters } from '../../elements/agents/modal/agents-filters '
import { AgentsUserModal } from '../../elements/agents/modal/agents-user'
import { FileUpload } from '../../elements/file-upload'
import { Loader } from '../../elements/loader'

import './agents.scss'
import closedVector from './assets/closed.webp'
import plusVector from './assets/plus.webp'

export interface IAgens extends AgentTypes.Data {
	mobcash_login?: string
}

export interface IUserCreateData {
	epos: string
	epos__id: string
	reddy_id: string
	status: string
	email: string
}

const Agents: FC = () => {
	const { addAlertMessage } = useAlertMessage()

	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isOpenAgent, setIsOpenAgent] = useState<boolean>(false)

	const [isOpenAgents, setIsOpenAgents] = useState<boolean>(false)
	const ticketsPageContentTab = useRef<HTMLDivElement>(null)
	const { createUserAsync, isLoadingCreateAgent } = useCreateAgent()
	const { updateUserAsync, isLoadingEditAgent } = useEditAgent()
	const { uploadFileAgentsAsync, isLoading: isLoadingFile } = useUploadFile()

	const [valuesOfSelectedFilters, setValuesOfSelectedFilters] =
		useState<AgentTypes.Data>({
			epos: undefined,
			epos__id: undefined,
			reddy_id: undefined,
			mobcash_login: undefined,
			is_blocked: false,
			id: undefined
		})
	const [closedFilter, setClosedFilter] = useState(false)

	const [agents, setAgents] = useState<AgentTypes.Data[] | undefined>()

	const [pagination, setPagination] = useState<PaginationTypes.Paginate>({
		page: 1,
		pages: 1,
		size: 12,
		total: 12
	})

	const getAgents = (updateForm: boolean = false) => {
		setIsLoading(true)

		const form = new FormData()
		Object.keys(valuesOfSelectedFilters).forEach(item => {
			// @ts-ignore
			if (valuesOfSelectedFilters[item] !== undefined) {
				// @ts-ignore
				let valueForm = valuesOfSelectedFilters[item]
				form.append(item, valueForm)
			} else form.append(item, '')
		})
		if (updateForm) {
			setPagination(prevState => {
				prevState.page = 1
				return { ...prevState }
			})
		}
		TicketsService.getAgents(pagination, form)
			.then(response => {
				setAgents(response.data.items)
				if (
					pagination.total !== response.data.total ||
					pagination.pages !== response.data.pages
				) {
					setPagination(prevState => {
						prevState.total = response.data.total
						prevState.pages = response.data.pages
						return { ...prevState }
					})
				}
			})
			.catch(e => {
				addAlertMessage(e, 'error')
				setAgents([])
				// setPagination({
				// 	page: 1,
				// 	pages: 1,
				// 	size: 12,
				// 	total: 12
				// })
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const addAgentHandler = async (data: IAgens, isUpdate?: boolean) => {
		const form = new FormData()
		for (const key in data) {
			//@ts-ignore
			if (data[key] !== undefined) {
				//@ts-ignore
				form.append(key, data[key])
			}
		}
		try {
			if (isUpdate) {
				await updateUserAsync(form)
			} else {
				await createUserAsync(form)
			}

			getAgents()
			setIsOpenAgent(false)
		} catch (error) {
			console.error(error)
		}
	}

	const addAgentsFile = async (data: File) => {
		const form = new FormData()
		form.append('file', data)
		try {
			await uploadFileAgentsAsync(form)
			getAgents()
			setIsOpenAgents(false)
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		getAgents(true)
	}, [valuesOfSelectedFilters])

	useEffect(() => {
		getAgents()
	}, [pagination])

	useEffect(() => {
		if (ticketsPageContentTab.current !== null) {
			// setPagination(prevState => {
			// 	const elementsPages = Math.floor(
			// 		(ticketsPageContentTab.current!.offsetHeight - 52) / 56
			// 	)
			// 	prevState.size = elementsPages <= 1 ? 1 : elementsPages
			// 	return { ...prevState }
			// })
		}
	}, [ticketsPageContentTab])


	return (
		<IndexPage
			title={'All Agents'}
			top_children={
				<div className='agents-page__top-search'>
					<Button image={plusVector} onClick={() => setIsOpenAgent(!isOpen)}>
						Add new agent
					</Button>
				</div>
			}
		>
			<div
				className={clsx(
					valuesOfSelectedFilters && 'agents-page__content',
					!valuesOfSelectedFilters && 'ticket-page__max-content'
				)}
			>
				<div className='agents-page__content-top'>
					<div className='agents-page__content-header'>
						<div className='agents-page__top-title'>
							Agents:<span> {pagination.total}</span>
						</div>
						<div className='agents-page__top-filters'>
							<FileUpload onClick={() => setIsOpenAgents(!isOpen)} />
							<button onClick={() => setIsOpen(!isOpen)}>
								Filters <FilterJSX />
							</button>
						</div>
					</div>
					<div className='agents-page__top-bottom'>
						<div className='agents-page__top-categories'>
							<div className='tickets-filters__categories-title'>
								Apply filters:
							</div>
							{Object.keys(valuesOfSelectedFilters).map(item => {
								// @ts-ignore
								const value = valuesOfSelectedFilters[item]

								if (value !== undefined) {
									const label =
										['true', 'false', true, false].includes(value) ||
										value === 'boolean'
											? value === 'true'
												? 'Blocked'
												: 'Not Blocked'
											: value

									return (
										<div key={item} className='tickets-filters__category'>
											<div className='tickets-filters__category-value'>
												{label}
											</div>
											<button
												onClick={() => {
													setClosedFilter(!closedFilter)
													setValuesOfSelectedFilters(prevState => {
														// @ts-ignore
														prevState[item] = undefined
														return { ...prevState }
													})
												}}
											>
												<img src={closedVector} alt='closed' />
											</button>
										</div>
									)
								}
								return null
							})}
						</div>
					</div>
				</div>
				<div ref={ticketsPageContentTab} className='agents-page__content-tab'>
					{isLoading && <Loader absolute transparent />}

					{agents && (
						<AgentsTable
							{...pagination}
							loading={isLoadingEditAgent}
							agents={agents}
							isOpenAgent={isOpenAgent}
							onSubmit={addAgentHandler}
						/>
					)}
					<div className='agents-page__content-pagination'>
						{pagination.total > pagination.size && (
							<Pagination
								pagination={pagination}
								setPagination={setPagination}
							/>
						)}
					</div>
				</div>

				<AgentsFilters
					setIsOpen={setIsOpen}
					isOpen={isOpen}
					valuesOfSelectedFilters={valuesOfSelectedFilters}
					setValuesOfSelectedFilters={setValuesOfSelectedFilters}
				/>
				<AgentsUserModal
					setIsOpen={setIsOpenAgent}
					isOpen={isOpenAgent}
					loading={isLoadingCreateAgent}
					onSubmit={addAgentHandler}
				/>

				<AgentsFileModal
					loading={isLoadingFile}
					setIsOpen={setIsOpenAgents}
					isOpen={isOpenAgents}
					onSubmit={addAgentsFile}
				/>
			</div>
		</IndexPage>
	)
}

export default Agents
