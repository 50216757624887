import React from 'react'
import { RouterProvider } from 'react-router-dom'

import './assets/base.scss'
import { MainProviders } from './providers'
import { router } from './providers/RouterProvider'

export const App = () => {
	return (
		<MainProviders>
			<RouterProvider router={router} />
		</MainProviders>
	)
}
