import React, { FC, PropsWithChildren } from 'react'

interface IProps extends PropsWithChildren {
	onClick: () => void
	image: string
}

const Button: FC<IProps> = ({ onClick, children, image }) => {
	return (
		<button onClick={onClick}>
			{children} {image && <img src={image} alt='plus' />}
		</button>
	)
}

export default Button
