import React, {FC, FormEvent} from 'react';
import './addAccount.scss'
import closedVector from './assets/closed.webp'
import ModalWindow from './typing';
import Service from "../../app/service/auto.service";

const AddAccount: FC<ModalWindow.ModalWindowProps> = ({isOpen, setIsOpen}) => {

    const onsubmit = (event: FormEvent) => {
        event.preventDefault()
        const form = new FormData((event.target as HTMLFormElement))

        const is_admin = (document.getElementById("role") as HTMLSelectElement).value === "Администратор" ? "true" : "false"

        form.append("is_admin",  is_admin)

        Service.postRegister(form).then(
            response => {
                if(response.status === 200){
                    alert("Пользователь зарегистрирован");
                    (event.target as HTMLFormElement).reset();
                    setIsOpen(false);
                }
            }
        )
    }


    return (
           <div className="add-account">
            <div className="add-account__content">
                <div className="add-account__title">
                <div className="add-account__closed"
                    onClick={() => setIsOpen(!isOpen)}>
                        <img src={closedVector} alt='closed'/>
                </div>
                    <h1>Добавить аккаунт</h1>
                    <div className='add-account__subtitle'>Введите данные, чтобы добавить новый аккаунт  </div>
                </div>
                <div className="add-account__info">
                    <form onSubmit={onsubmit}>
                        <div className="add-account__info-content">
                            <label htmlFor='email'>Email/Логин</label>
                            <input
                                type="text"
                                name="username"
                                placeholder='Example@mail.com'
                                id='email'
                            />
                        </div>
                        <div className="add-account__info-content">
                            <label htmlFor='email'>Пароль</label>
                            <input
                                type="password"
                                name="password"
                                placeholder='************'
                                id='email'
                            />
                        </div>
                        <div className="add-account__info-content">
                            <label htmlFor='role'>Выберите роль</label>
                            <select name="categoryRoles" id="role">
                                <option>Пользователь</option>
                                <option>Администратор</option>
                            </select>
                        </div>
                        <button type="submit" className="add-account__enter-button">
                            Добавить аккаунт
                        </button>
                    </form>
                </div>
            </div>
           </div>
    );
};

export default AddAccount;
