import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'

import { AgentTypes } from '../../../app/api/agents/agents.types'
import PaginationTypes from '../../../components/pagination/pagination.types'
import { IAgens } from '../../../pages/agents'
import { AgentsUserModal } from '../modal/agents-user'

import './agents.scss'
import edit from './edit.svg'

interface IProps extends PaginationTypes.Paginate {
	agents: AgentTypes.Data[]
	isOpenAgent?: boolean
	loading: boolean
	onSubmit: (data: IAgens, isUpdate?: boolean) => void
}

export const AgentsTable: FC<IProps> = ({
	agents,
	page,
	size,
	onSubmit,
	isOpenAgent,
	loading
}) => {
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [ticketData, setTicketData] = useState<any>(null)

	console.log({loading});
	

	const headerCategory = [
		'',
		'N',
		'Epos',
		'Epos id',
		'Teamcash Login',
		'REddy id',
		'Epos Status'
	]

	const onClick = (ticket: any) => {
		setIsOpenModal(true)
		setTicketData(ticket)
	}
	useEffect(() => {
		if (isOpenAgent) {
			setIsOpenModal(false)
		}
	}, [isOpenAgent])

	const onUpdateUser = (data: IAgens) => {
		onSubmit(data, true)
	}


	return (
		<div className='agents-table'>
			<table>
				<thead>
					<tr className='agents-table__category-filters'>
						{headerCategory.map(category => (
							<th key={category} className={category}>
								{category}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					<tr className='agents-table__tab-tr'>
						{agents.map((agent, index = 1) => (
							<React.Fragment key={index}>
								<td
									className={`agents-table-tab-td ${
										!agent.is_blocked && 'active'
									}`}
								>
									<div
										className='agents-table-tab-td__number'
										onClick={() => onClick(agent)}
									>
										<img src={edit} />
									</div>
									<div>{page * size - size + index + 1}</div>
									<div className='agents-table-tab-td__epos'>{agent.epos}</div>
									<div className='agents-table-tab-td__epos-id'>
										{agent.epos__id}
									</div>
									<div className='agents-table-tab-td__mobCash-login'>
										{agent.mobcash_login}
									</div>
									<div className='agents-table-tab-td__reddy-id'>
										{agent.reddy_id}
									</div>
									<div
										className={clsx(
											'agents-table-tab-td__status',
											!agent.is_blocked && 'status-solved'
										)}
									>
										<p>{agent.is_blocked ? 'Blocked' : 'Not blocked'}</p>
									</div>
								</td>
							</React.Fragment>
						))}
					</tr>
				</tbody>
			</table>
			<AgentsUserModal
				isOpen={isOpenModal}
				setIsOpen={setIsOpenModal}
				data={ticketData}
				loading={loading}
				onSubmit={onUpdateUser}
			/>
		</div>
	)
}
