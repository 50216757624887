import React, { FC } from 'react';
import IndexPage from '..';

const Statistic: FC = () => {
    return (
        <IndexPage title={"Statistic"} top_children={
            <></>
        }>
            <div>
                Что-то там
            </div>
        </IndexPage>
    );
};

export default Statistic;
