import axios from 'axios'
import Cookies from 'js-cookie'

import Service from '../service/auto.service'

// import AuthService from 'src/services/auth/auth.service'
import {
	errorCatch,
	getContentType,
	removeToken,
	saveToken
} from './api.helpres'

const axiosInstance = axios.create({
	baseURL: 'https://api.adminhelpbot.com/api/v1'
})

axiosInstance.interceptors.request.use(config => {
	if (config.url !== 'auth/refresh') {
		const accessToken = Cookies.get('accessToken')
		if (config.headers && accessToken)
			config.headers.Authorization = `Bearer ${accessToken}`
	}
	return config
})
// config в консоль

axiosInstance.interceptors.response.use(
	config => config,
	async error => {
		const originalRequest = error.config
		const errorsCode = [401, 403, 422]

		if (
			(errorsCode.includes(error?.response?.status) ||
				errorCatch(error) === 'jwt expired' ||
				errorCatch(error) === 'Signature has expired' ||
				errorCatch(error) === 'jwt must be provided') &&
			error.config &&
			!error.config._isRetry
		) {
			originalRequest._isRetry = true
			try {
				const refreshToken = Cookies.get('refreshToken')
				if (refreshToken) {
					const data = await Service.postRefreshAccessToken()

					saveToken(data.data)
					return axiosInstance.request(originalRequest)
				}
			} catch (error) {
				removeToken()

				if (errorCatch(error) === 'jwt expired') removeToken()
			}
		}

		throw error
	}
)

export default axiosInstance
