import React, { FC } from 'react'

import './file.scss'
import { ReactComponent as FileUploadIcon } from './fileUpload.svg'

interface IProps {
	onClick: () => void
}

export const FileUpload: FC<IProps> = ({ onClick }) => {
	return (
		<div className='file-upload' onClick={onClick}>
			<FileUploadIcon />
		</div>
	)
}
