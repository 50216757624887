import IndexPage from '..'
import TicketsTypes from 'app/api/tickets/tickets.types'
import { TicketsService } from 'app/service/tIckets.service'
import Pagination from 'components/pagination/Pagination'
import PaginationTypes from 'components/pagination/pagination.types'
import Ticket from 'elements/ticket/Ticket'
import TicketsFilters from 'elements/tickets-filters'
import React, { FC, useEffect, useRef, useState } from 'react'

import closedVector from './assets/closed.webp'
import searchVector from './assets/searchVector.webp'
import './tickets.scss'

const Tikets: FC = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const ticketsPageContentTab = useRef<HTMLDivElement>(null)
	const [valuesOfSelectedFilters, setValuesOfSelectedFilters] =
		useState<TicketsTypes.Data>({
			reddy_id: undefined,
			epos: undefined,
			epos_id: undefined,
			mobcash_login: undefined,
			problem: undefined,
			status: undefined,
			chat_id_user: undefined,
			created_at: undefined
		})
	const [closedFilter, setClosedFilter] = useState<boolean>(false)

	const [tickets, setTickets] = useState<TicketsTypes.Data[] | undefined>()

	const [pagination, setPagination] = useState<PaginationTypes.Paginate>({
		page: 1,
		pages: 1,
		size: 12,
		total: 12
	})

	const getTickets = (updateForm: boolean = false) => {
		const form = new FormData()
		Object.keys(valuesOfSelectedFilters).map(item => {
			// @ts-ignore
			if (valuesOfSelectedFilters[item] !== undefined) {
				// @ts-ignore
				let valueForm = valuesOfSelectedFilters[item]
				if (item === 'problem' || item === 'status') {
					// @ts-ignore
					valueForm =
						// @ts-ignore
						valuesOfSelectedFilters[item].charAt(0).toLowerCase() +
						// @ts-ignore
						valuesOfSelectedFilters[item].slice(1)
				}
				form.append(item, valueForm)
			} else form.append(item, '')
		})
		if (updateForm) {
			setPagination(prevState => {
				prevState.page = 1
				return { ...prevState }
			})
		}
		TicketsService.getTickets(pagination, form).then(response => {
			setTickets(response.data.items)
			if (
				pagination.total !== response.data.total ||
				pagination.pages !== response.data.pages
			) {
				setPagination(prevState => {
					prevState.total = response.data.total
					prevState.pages = response.data.pages
					return { ...prevState }
				})
			}
		})
	}

	useEffect(() => {
		getTickets(true)
	}, [valuesOfSelectedFilters])

	useEffect(() => {
		getTickets()
	}, [pagination])

	useEffect(() => {
		if (ticketsPageContentTab.current !== null) {
			setPagination(prevState => {
				const elementsPages = Math.floor(
					(ticketsPageContentTab.current!.offsetHeight - 52) / 56
				)
				prevState.size = elementsPages <= 1 ? 1 : elementsPages
				return { ...prevState }
			})
		}
	}, [ticketsPageContentTab])

	return (
		<IndexPage title={'All Tickets'} top_children={<></>}>
			<div
				className={
					valuesOfSelectedFilters
						? 'tickets-page__content'
						: 'ticket-page__max-content'
				}
			>
				<div className='tickets-page__content-top'>
					<div className='tickets-page__content-header'>
						<div className='tickets-page__top-title'>
							Tickets: {pagination.total}
						</div>
						<div className='tickets-page__top-filters'>
							<button onClick={() => setIsOpen(!isOpen)}>filters</button>
						</div>
					</div>
					<div className='tickets-page__top-bottom'>
						<div className='tickets-page__top-categories'>
							<div className='tickets-filters__categories-title'>
								Apply filters:
							</div>
							{Object.keys(valuesOfSelectedFilters).map(item => {
								// @ts-ignore
								if (valuesOfSelectedFilters[item] !== undefined) {
									return (
										<div key={item} className='tickets-filters__category'>
											<div className='tickets-filters__category-value'>
												{
													// @ts-ignore
													valuesOfSelectedFilters[item]
												}
											</div>
											<button
												onClick={() => {
													setClosedFilter(!closedFilter)
													setValuesOfSelectedFilters(prevState => {
														// @ts-ignore
														prevState[item] = undefined
														return { ...prevState }
													})
												}}
											>
												<img src={closedVector} alt='closed' />
											</button>
										</div>
									)
								}
							})}
						</div>
					</div>
				</div>
				<div ref={ticketsPageContentTab} className='tickets-page__content-tab'>
					{tickets && <Ticket {...pagination} tickets={tickets} />}
				</div>
				<div className='tickets-page__content-pagination'>
					{pagination.total > pagination.size && (
						<Pagination pagination={pagination} setPagination={setPagination} />
					)}
				</div>
				{isOpen ? (
					<TicketsFilters
						setIsOpen={setIsOpen}
						isOpen={isOpen}
						valuesOfSelectedFilters={valuesOfSelectedFilters}
						setValuesOfSelectedFilters={setValuesOfSelectedFilters}
					/>
				) : null}
			</div>
		</IndexPage>
	)
}

export default Tikets
