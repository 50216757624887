import React, { FC, FormEvent } from 'react'

import TicketsTypes from '../../../../app/api/tickets/tickets.types'
import ModalWindow from '../../../add-account/typing'

import './agentsFilters.scss'
import closedVector from './assets/closed.webp'
import { Modal } from '@mui/material'

export const AgentsFilters: FC<ModalWindow.ModalWindowPropsAgents> = ({
	isOpen,
	setIsOpen,
	valuesOfSelectedFilters,
	setValuesOfSelectedFilters
}) => {
	const useTicketsFilters = (event: FormEvent) => {
		event.preventDefault()
		;(event.target as HTMLFormElement)
			.querySelectorAll('input, select')
			.forEach(element => {
				if (setValuesOfSelectedFilters !== undefined) {
					setValuesOfSelectedFilters((state: TicketsTypes.Data) => {
						// @ts-ignore
						if (element.value) {
							if (element.tagName === 'SELECT') {
								// @ts-ignore
								if (element.value !== 'All') {
									// @ts-ignore
									state[element.name] = element.value
								} else {
									// @ts-ignore
									state[element.name] = undefined
								}
							} else {
								// @ts-ignore
								state[element.name] = element.value
							}
						} else {
							// @ts-ignore
							state[element.name] = undefined
						}

						return { ...state }
					})
				}
			})
		setIsOpen(!isOpen)
	}

	return (
		<Modal open={isOpen} onClose={() => setIsOpen(false)}>
			<div className='agents-filters'>
				<div className='agents-filters__content'>
					<div
						className='agents-filters__closed'
						onClick={() => setIsOpen(!isOpen)}
					>
						<img src={closedVector} alt='closaed' />
					</div>
					<form onSubmit={useTicketsFilters}>
						<div className='agents-filters__blocks'>
							<div className='agents-filters__top'>
								<div className='agents-filters__block-item'>
									<label>Epos</label>
									<input
										defaultValue={valuesOfSelectedFilters?.epos}
										name='epos'
										type='text'
										placeholder='Entry login...'
									/>
								</div>
								<div className='agents-filters__block-item'>
									<label>Epos id</label>
									<input
										defaultValue={valuesOfSelectedFilters?.epos__id}
										name='epos__id'
										type='number'
										placeholder='Entry id...'
									/>
								</div>
								<div className='agents-filters__block-item'>
									<label>Reddy id</label>
									<input
										defaultValue={valuesOfSelectedFilters?.reddy_id}
										name='reddy_id'
										type='number'
										placeholder='Entry id...'
									/>
								</div>
								<div className='agents-filters__block-item'>
									<label>Status</label>
									<select
										//@ts-ignore
										defaultValue={valuesOfSelectedFilters?.is_blocked}
										name='is_blocked'
										placeholder='Choose...'
									>
										<option>All</option>
										<option value={'true'}>Blocked</option>
										<option value={'false'}>Not blocked</option>
									</select>
								</div>
							</div>
						</div>
						<button className='agents-filters__btn' type='submit'>
							Apply filters
						</button>
					</form>
				</div>
			</div>
		</Modal>
	)
}
