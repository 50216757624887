import React, {FC} from 'react';
import './pagination.scss';
import leftVector from './assets/leftVector.webp';
import rigthVector from './assets/rigthVector.webp'
import PaginationTypes from "../../components/pagination/pagination.types";


const Pages: FC<PaginationTypes.PaginateProps> = ({pagination, setPagination}) => {
    if(pagination.pages >= 7){
        let pages: Array<number|null> = []
        switch (true){
            case (pagination.page <= 4):
                pages = [1,2,3,4,5,null,pagination.pages]
                break
            case (3 <= pagination.page && pagination.page < pagination.pages - 3):
                pages = [1,null,pagination.page - 1,pagination.page, pagination.page + 1,null,pagination.pages]
                break
            case (pagination.pages - 3 <= pagination.page):
                pages = [1,null,pagination.pages - 4,pagination.pages - 3, pagination.pages - 2, pagination.pages - 1,pagination.pages]
                break
        }

        return <div className="pagination__btns">
            {
                pages.map((page, index) => {
                    if(page !== null){
                        return <button className={page == pagination.page ? "active" : ""} key={index}
                                       onClick={() => setPagination((prevState: PaginationTypes.Paginate) => {
                                           prevState.page = page!
                                           return {...prevState}
                                       })}>
                            <p>
                                {page}
                            </p>
                        </button>
                    } else return <p key={index}>...</p>

                })
            }
        </div>
    } else return <div className="pagination__btns" style={{gridTemplateColumns: `repeat(${pagination.pages}, 1fr)`}}>
        {Array.from(Array(pagination.pages).keys()).map((page) => {
            page += 1
            return <button className={page == pagination.page ? "active" : ""} key={page}
                           onClick={() => setPagination((prevState: PaginationTypes.Paginate) => {
                               prevState.page = page
                               return {...prevState}
                           })}>
                <p>
                    {page}
                </p>
            </button>
        })}
    </div>
}

const Pagination: FC<PaginationTypes.PaginateProps> = ({pagination, setPagination}) => {
    return (
        <div className='pagination'>
            <div className="pagination__left-vector">
                {
                    pagination.page > 1 &&
                    <button onClick={() => setPagination((prevState: PaginationTypes.Paginate) => {
                        prevState.page -= 1
                        return {...prevState}
                    })}>
                        <img src={leftVector} alt='leftVector'></img>
                    </button>
                }
            </div>
            <Pages pagination={pagination} setPagination={setPagination}/>
            <div className="pagination__rigth-vector">
                {
                    pagination.page < pagination.pages && <button onClick={() => setPagination((prevState: PaginationTypes.Paginate) => {
                        prevState.page += 1
                        return {...prevState}
                    })}>
                        <img src={rigthVector} alt='rigthVector'></img>

                    </button>
                }
            </div>
        </div>
    );
};

export default Pagination;
