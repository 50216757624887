import { AxiosError } from 'axios'
import { useAlert } from 'react-alert'

export function useAlertMessage() {
	const alert = useAlert()

	const addAlertMessage = (
		message: string | string[] | AxiosError<any>,
		type: 'success' | 'info' | 'error' = 'success'
	) => {
		const resultMessage = Array.isArray(message)
			? message.join('\n')
			: typeof message === 'string'
			? message
			: message?.response?.data?.error || (message?.message as string)
		alert[type](resultMessage)
	}

	return { addAlertMessage }
}
