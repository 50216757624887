import PaginationTypes from '../../components/pagination/pagination.types'
import { AgentTypes } from '../api/agents/agents.types'
import axiosInstance from '../api/axiosInstance'
import TicketType from '../api/tickets/tickets.types'

const getAgentsUrl = 'cash_registers/'

export const TicketsService = {
	async getTickets(pagination: PaginationTypes.Paginate, form: FormData) {
		return await axiosInstance.post<TicketType.DataResponses>(
			`ticket/tickets_all/?page=${pagination.page}&size=${pagination.size}`,
			form
		)
	},

	async getAgents(pagination: PaginationTypes.Paginate, form: FormData) {
		const { page, size } = pagination
		return await axiosInstance.post<AgentTypes.DataResponses>(
			`cash_registers/get_all/`,
			form,
			{
				params: {
					page,
					size
				}
			}
		)
	},

	async editAgent(form: FormData) {
		return await axiosInstance.patch<AgentTypes.Data>(
			`${getAgentsUrl}edit_user_data/`,
			form
		)
	},
	async blockingAgent(form: FormData) {
		return await axiosInstance.patch<AgentTypes.DataResponses>(
			`${getAgentsUrl}blocking_user/`,
			form
		)
	},

	async createAgent(form: FormData) {
		return await axiosInstance.post<AgentTypes.Data>(
			`${getAgentsUrl}create_user_data/`,
			form
		)
	},
	async uploadFile(form: FormData) {
		return await axiosInstance.post<AgentTypes.File>(
			`${getAgentsUrl}upload-excel/`,
			form
		)
	}
}
