import clsx from 'clsx'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { AccountJSX, AgentsJSX, StatisticsJSX, TicketsJSX } from './assets'
import accountVecotr from './assets/account.webp'
import statisticVector from './assets/statistic.webp'
import ticketsVector from './assets/tickets.webp'
import './menu.scss'

const Menu = () => {
	const location = useLocation()

	const menuData = [
		{
			name: 'Tickets',
			to: '/',
			image: <TicketsJSX />
		},
		// {
		// 	name: 'Statistic',
		// 	to: '/statistic',
		// 	image: <StatisticsJSX />
		// },
		{
			name: 'Agents',
			to: '/agents',
			image: <AgentsJSX />
		}
	]

	return (
		<div className='menu'>
			<div className='menu-content'>
				<div className='menu-content__top'>
					{menuData.map(item => (
						<div
							className={clsx(
								'menu-item',
								location.pathname === item.to && ['menu-item-active']
							)}
							key={item.name}
						>
							<Link to={item.to}>
								<div className='menu-item__img'>{item.image}</div>
								<div className='menu-item__title'>{item.name}</div>
							</Link>
						</div>
					))}
				</div>
				<div className='menu-content__bottom'>
					<div
						className={clsx(
							'menu-item',
							location.pathname === '/account' && ['menu-item-active']
						)}
					>
						<Link to={'/account'}>
							<div className='menu-item__img'>{<AccountJSX />}</div>
							<div className='menu-item__title'>Account</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Menu
