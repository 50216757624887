import React from 'react'
import clsx from 'clsx'
import { FC } from 'react'

import styles from './Loader.module.scss'

export interface IProps {
	hiddenText?: boolean
	absolute?: boolean
	fixed?: boolean
	isMini?: boolean
	transparent?: boolean
}

export const Loader: FC<IProps> = ({
	hiddenText,
	absolute,
	isMini,
	transparent
}) => {
	return (
		<div
			className={clsx(
				styles.wrapper,
				absolute && styles.absolute,
				transparent && styles.transparent,
				isMini && styles.miniLoader
			)}
		>
			<div className={styles.loader}>
				<div className={clsx(styles['lds-roller'])}>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				{hiddenText ? null : <div className={styles.text}>Loader</div>}
			</div>
		</div>
	)
}
