import React, { FC } from 'react';
import TicketType from './typing';
import './ticketCard.scss';
import closedVector from './assets/closed.webp'

const TicketCard: FC<TicketType.TicketData> = ({ticket, setIsOpen}) => {

    return (
        <div className="ticket-widget">
        <div className="ticket-widget__top">
            <h2>Ticket №{ticket.id}</h2>
            <button onClick={() => {
                console.log("close")
                setIsOpen(null)
            }}><img src={closedVector} alt='closed'/></button>
        </div>
            <div className="ticket-widget__content">
            <div className="ticket-widget__content-reddy-id">
                <h3>Reddy id</h3>
                {ticket.reddy_id}
            </div>
            <div className="ticket-widget__content-epos">
                <h3>Epos</h3>
                {ticket.epos}
            </div>
            <div className="ticket-widget__content-epos-id">
                <h3>Epos id</h3>
                {ticket.epos_id}
            </div>
            <div className="ticket-widget__content-mobCash-login">
                <h3>Mobcash login</h3>
                {ticket.mobcash_login}
            </div>
            <div className="ticket-widget__content-problem">
                <h3>Problen</h3>
                {ticket.problem}
            </div>
            <div className="ticket-widget__content-status">
                <h3>Status</h3>
                <div className={`ticket-tab-td__status ${ticket.status === "solved" ? "status-solved" : ""}`}><p>{ticket.status!.charAt(0).toUpperCase() + ticket.status!.slice(1)}</p></div>
            </div>
            <div className="ticket-widget__content-message">
                <h3>Message</h3>
                {ticket.chat_id_user}
            </div>
            <div className="ticket-widget__content-open-time">
                <h3>Tiime</h3>
                {ticket.created_at?.toString()}</div>
        </div>
    </div>
    );
};

export default TicketCard;
