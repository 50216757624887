import { Button } from '@mui/joy'
import { Box, Modal } from '@mui/material'
import { useAlertMessage } from 'hooks/useMessageAlert'
import { MuiFileInput } from 'mui-file-input'
import React, { FC, useEffect } from 'react'

import ModalWindow from '../../../add-account/typing'

import './agentsuser.scss'
import closedVector from './assets/closed.webp'
import { ReactComponent as FileIconJSX } from './assets/fileUpload.svg'

interface IProps extends ModalWindow.ModalWindowProps {
	onSubmit: (data: File) => void
	loading: boolean
}

export const AgentsFileModal: FC<IProps> = ({
	isOpen,
	setIsOpen,
	onSubmit,
	loading
}) => {
	const [file, setFile] = React.useState<File | null>(null)
	const { addAlertMessage } = useAlertMessage()

	const handleChange = (newFile: File | null) => {
		setFile(newFile)
	}

	const onSubmitFile = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		if (!file?.type.includes('sheet'))
			return addAlertMessage('Only .excel file', 'error')

		if (file) onSubmit(file)
	}

	useEffect(() => {
		if (!isOpen) setFile(null)
	}, [isOpen])

	return (
		<Modal open={isOpen} onClose={() => setIsOpen(false)}>
			<div className='agents-users'>
				<div className='agents-users__content'>
					<div
						className='agents-users__closed'
						onClick={() => setIsOpen(!isOpen)}
					>
						<img src={closedVector} alt='closaed' />
					</div>
					<Box
						sx={{
							flexDirection: 'column',
							gap: 3,
							display: 'flex',
							textAlign: 'center'
						}}
					>
						<Box sx={{ flexDirection: 'column', gap: 1, display: 'flex' }}>
							<h3 style={{ fontSize: '40px', margin: 0 }}>Добавить агентов</h3>
							<p style={{ color: '#6F6F82', margin: 4 }}>
								Прикрепите файл, чтобы добавить агентов
							</p>
						</Box>

						<form style={{ width: '100%' }} onSubmit={onSubmitFile} className='agents-users__form'>
							<Box sx={{ flexDirection: 'column', gap: 3, display: 'flex' }}>
								<div className='agents-users__input-file'>
									<MuiFileInput
										size='medium'
										label='File'
										required
										placeholder='Download your file'
										color='primary'
										value={file}
										onChange={handleChange}
									/>
									{!file && <FileIconJSX />}
								</div>
								<Button
									loading={loading}
									type='submit'
									style={{ background: '#242BD7', padding: '24px' }}
								>
									Добавить агентов
								</Button>
							</Box>
						</form>
					</Box>
				</div>
			</div>
		</Modal>
	)
}
