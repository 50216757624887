import { useMutation, useQueryClient } from '@tanstack/react-query'

import { TicketsService } from 'app/service/tIckets.service'
import { useAlertMessage } from 'hooks/useMessageAlert'

export const useEditAgent = () => {
	const queryClient = useQueryClient()
	const { addAlertMessage } = useAlertMessage()

	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['edit agent shop'],
		(data: FormData) => TicketsService.editAgent(data),
		{
			onSuccess: data => {
				const message = Array.isArray(data?.data) ? data?.data || '' : 'Success'
				queryClient.invalidateQueries(['tickets list'])
				addAlertMessage(message, 'info')
			},
			onError: (error: any) => {
				addAlertMessage(error, 'error')
			}
		}
	)

	return {
		isLoadingEditAgent: isLoading,
		isError,
		mutate,
		updateUserAsync: mutateAsync
	}
}
