import Cookies from 'js-cookie';
import Tokens from './auth/auth.typing';


export const getContentType = () => ({
	'Content-Type': 'application/json'
})

export const errorCatch = (error: any): string => {
    if (error.response && error.response.data) {
        return typeof error.response.data.detail === 'object'
            ? error.response.data.detail[0].msg
            : error.response.data.detail
        }
    return error.message
}

export const saveToken = (data: Tokens.AllTokens) => {
    if(data.refresh_token){
        Cookies.set('refreshToken', data.refresh_token)
    }
    Cookies.set('accessToken', data.access_token)
}

export const removeToken = () => {
    Cookies.remove('refreshToken')
    Cookies.remove('accessToken')
    window.location.reload()
}
