import { useMutation, useQueryClient } from '@tanstack/react-query'

import { TicketsService } from '../../../app/service/tIckets.service'
import { useAlertMessage } from '../../../hooks/useMessageAlert'

export const useCreateAgent = () => {
	const queryClient = useQueryClient()
	const { addAlertMessage } = useAlertMessage()

	const { isLoading, isError, mutate, mutateAsync } = useMutation(
		['create agent '],
		(data: FormData) => TicketsService.createAgent(data),

		{
			onSuccess: data => {
				const message = Array.isArray(data.data) ? data.data : 'Success'
				queryClient.invalidateQueries(['tickets list'])
				addAlertMessage(message, 'info')
			},
			onError: (error: any) => {
				addAlertMessage(error, 'error')
			}
		}
	)

	return {
		isLoadingCreateAgent: isLoading,
		isError,
		mutate,
		createUserAsync: mutateAsync
	}
}
