import React, { FC, useState } from 'react';
import './ticket.scss';
import { ticketData, ticketCategories } from './ticket.data';
import TicketCard from '../ticket-card/TicketCard';
import TicketsTypes from "../../elements/ticket-card/typing";

const Ticket: FC<TicketsTypes.TicketProps> = ({tickets, page, size}) => {
    const [isOpen, setIsOpen] = useState<number|null>(null);

    return (
        <div className="ticket">
            <table>
            <thead>
                <tr className="ticket__category-filters">
                    {ticketCategories.map((category) => (
                        <th className={category}>
                            {category}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr className="ticket__tab-tr">
                    {tickets.map((ticket, index = 1) => (
                        <>
                            <td className={`ticket-tab-td ${ticket.status === "Solved" && "active"}`} onClick={() => {
                                const ticket_id = ((page * size) - size + index + 1)
                                ticket.id = ticket_id.toString()
                                setIsOpen(ticket_id)
                            }}>
                                <div className="ticket-tab-td__number">{(page * size) - size + index + 1}</div>
                                <div className="ticket-tab-td__reddy-id">{ticket.reddy_id}</div>
                                <div className="ticket-tab-td__epos">{ticket.epos}</div>
                                <div className="ticket-tab-td__epos-id">{ticket.epos_id}</div>
                                <div className="ticket-tab-td__mobCash-login">{ticket.mobcash_login}</div>
                                <div className="ticket-tab-td__problem">{ticket.problem}</div>
                                <div className={`ticket-tab-td__status ${ticket.status === "solved" ? "status-solved" : ""}`}><p>{ticket.status!.charAt(0).toUpperCase() + ticket.status!.slice(1)}</p></div>
                                <div className="ticket-tab-td__message">{ticket.messages?.map((element, index) => <>{element.message}{(ticket.messages!.length - 1) === index ? "" : ","}<br/></>)}</div>
                                <div className="ticket-tab-td__open-time">{new Date(ticket.created_at! + "+00:00").toLocaleString('ru')}<br/>{ticket.status === "solved" && new Date(ticket.closed_at! + "+00:00").toLocaleString('ru')}</div>
                            </td>
                            {isOpen === ((page * size) - size + index + 1) && (
                                <TicketCard ticket={ticket} setIsOpen={setIsOpen}/>
                            )}
                        </>

                    ))}
                </tr>
            </tbody>
            </table>
        </div>
    );
};

export default Ticket;
