import { createBrowserRouter } from 'react-router-dom'
import { ProtectedRoute } from './protected-route'
import Tikets from 'pages/tickets'
import React from 'react'
import Statistic from 'pages/statistic'
import Agents from 'pages/agents'
import Account from 'pages/account'

export const router = createBrowserRouter([
	{
		// Главная страница (Разводяшка)
		path: '/',
		element: (
			<ProtectedRoute>
				<Tikets />
			</ProtectedRoute>
		)
	},
  {
		path: '/statistic',
		element: (
			<ProtectedRoute>
				<Statistic />
			</ProtectedRoute>
		)
	},
  {
		path: '/agents',
		element: (
			<ProtectedRoute>
				<Agents />
			</ProtectedRoute>
		)
	},
  {
		path: '/account',
		element: (
			<ProtectedRoute>
				<Account />
			</ProtectedRoute>
		)
	},
])
