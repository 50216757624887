import React, {FC, PropsWithChildren, ReactElement} from "react";
import './index.scss'
import Menu from "../components/menu";


interface IndexPageProps extends PropsWithChildren {
    title: string
    top_children: ReactElement
}

const IndexPage: FC<IndexPageProps> = ({children, title, top_children}) => {

    return (
        <>
            <Menu />
            <div className='page'>
                <div className="page__top">
                    <div className="page__top-logo">{title}</div>
                    {top_children}
                </div>
                {children}
            </div>
        </>
    );
};

export default IndexPage;
