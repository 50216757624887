    export const ticketData = [
        {
            reddy_id: 15658970212,
            epos: 'Ranprup - win 24./7',
            epos_id: 1142352351,
            mobcash_login: '234dfafasd faa2DASFSdg',
            problem: 'Mobcash Login problem',
            status: 'In processing',
            chat_id_user: "Password: 34533 New Pass: 4435",
            created_at: "2023-09-07T16:33:29.530Z",
        },
        {
            reddy_id: 15658970212,
            epos: 'Ranprup - win 24./7',
            epos_id: 1142352351,
            mobcash_login: '234dfafasd faa2DASFSdg',
            problem: 'Mobcash Login problem',
            status: 'Solved',
            chat_id_user: "Password: 34533 New Pass: 4435",
            created_at: "2023-09-07T16:33:29.530Z",
        },
        {
            reddy_id: 15658970212,
            epos: 'Ranprup - win 24./7',
            epos_id: 1142352351,
            mobcash_login: '234dfafasd faa2DASFSdg',
            problem: 'Mobcash Login problem',
            status: 'Solved',
            chat_id_user: "Password: 34533 New Pass: 4435",
            created_at: "2023-09-07T16:33:29.530Z",
        },
        {
            reddy_id: 15658970212,
            epos: 'Ranprup - win 24./7',
            epos_id: 1142352351,
            mobcash_login: '234dfafasd faa2DASFSdg',
            problem: 'Mobcash Login problem',
            status: 'Solved',
            chat_id_user: "Password: 34533 New Pass: 4435",
            created_at: "2023-09-07T16:33:29.530Z",
        },
        {
            reddy_id: 15658970212,
            epos: 'Ranprup - win 24./7',
            epos_id: 1142352351,
            mobcash_login: '234dfafasd faa2DASFSdg',
            problem: 'Mobcash Login problem',
            status: 'Solved',
            chat_id_user: "Password: 34533 New Pass: 4435",
            created_at: "2023-09-07T16:33:29.530Z",
        },
        {
            reddy_id: 15658970212,
            epos: 'Ranprup - win 24./7',
            epos_id: 1142352351,
            mobcash_login: '234dfafasd faa2DASFSdg',
            problem: 'Mobcash Login problem',
            status: 'Solved',
            chat_id_user: "Password: 34533 New Pass: 4435",
            created_at: "2023-09-07T16:33:29.530Z",
        },
        {
            reddy_id: 15658970212,
            epos: 'Ranprup - win 24./7',
            epos_id: 1142352351,
            mobcash_login: '234dfafasd faa2DASFSdg',
            problem: 'Mobcash Login problem',
            status: 'Solved',
            chat_id_user: "Password: 34533 New Pass: 4435",
            created_at: "2023-09-07T16:33:29.530Z",
        },
        {
            reddy_id: 15658970212,
            epos: 'Ranprup - win 24./7',
            epos_id: 1142352351,
            mobcash_login: '234dfafasd faa2DASFSdg',
            problem: 'Mobcash Login problem',
            status: 'Solved',
            chat_id_user: "Password: 34533 New Pass: 4435",
            created_at: "2023-09-07T16:33:29.530Z",
        },
        {
            reddy_id: 15658970212,
            epos: 'Ranprup - win 24./7',
            epos_id: 1142352351,
            mobcash_login: '234dfafasd faa2DASFSdg',
            problem: 'Mobcash Login problem',
            status: 'Solved',
            chat_id_user: "Password: 34533 New Pass: 4435",
            created_at: "2023-09-07T16:33:29.530Z",
        },
        {
            reddy_id: 15658970212,
            epos: 'Ranprup - win 24./7',
            epos_id: 1142352351,
            mobcash_login: '234dfafasd faa2DASFSdg',
            problem: 'Mobcash Login problem',
            status: 'Solved',
            chat_id_user: "Password: 34533 New Pass: 4435",
            created_at: "2023-09-07T16:33:29.530Z",
        },
        {
            reddy_id: 15658970212,
            epos: 'Ranprup - win 24./7',
            epos_id: 1142352351,
            mobcash_login: '234dfafasd faa2DASFSdg',
            problem: 'Mobcash Login problem',
            status: 'Solved',
            chat_id_user: "Password: 34533 New Pass: 4435",
            created_at: "2023-09-07T16:33:29.530Z",
        },
        {
            reddy_id: 15658970212,
            epos: 'Ranprup - win 24./7',
            epos_id: 1142352351,
            mobcash_login: '234dfafasd faa2DASFSdg',
            problem: 'Mobcash Login problem',
            status: 'Solved',
            chat_id_user: "Password: 34533 New Pass: 4435",
            created_at: "2023-09-07T16:33:29.530Z",
        },
]

export const ticketCategories = ['N.', 'REDDY ID', 'EPOS', 'EPOS ID', 'MOBCASH LOGIN', 'PROBLEM', 'STATUS', 'MESSAGE', 'OPEN TIME']
