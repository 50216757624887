import { useAlertMessage } from 'hooks/useMessageAlert'
import React, { FC, FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'

import { saveToken } from '../../app/api/api.helpres'
import Service from '../../app/service/auto.service'

import vector from './assets/vector.webp'
import './login.scss'

const Login: FC = () => {
	const { addAlertMessage } = useAlertMessage()

	const [seePass, setSeePass] = useState<boolean>(false)

	const useLogin = (event: FormEvent) => {
		event.preventDefault()

		const formData = new FormData(event.target as HTMLFormElement)

		Service.postLogin(formData)
			.then(response => {
				saveToken(response.data)
				window.location.reload()
			})
			.catch(e => {
				addAlertMessage(e, 'error')
			})
	}

	return (
		<div className='login-page'>
			<div className='login'>
				<div className='login__content'>
					<div className='login__title'>
						<h1>Войти в аккаунт</h1>
						<div className='login__subtitle'>
							Введите свой логин и пароль в для входа в систему{' '}
						</div>
					</div>
					<div className='login__info'>
						<form onSubmit={useLogin}>
							<div className='login__info-content'>
								<label htmlFor='email'>Ваш Email/Логин</label>
								<input
									type='text'
									placeholder='Example@mail.com'
									id='email'
									name='username'
								/>
							</div>
							<div className='login__info-content'>
								<label htmlFor='pass'>Введите пароль</label>
								<input
									type={seePass ? 'text' : 'password'}
									placeholder='***'
									id='pass'
									name='password'
								/>
								<div className='login__see-pass'>
									<img
										src={vector}
										alt='see password'
										onClick={() => setSeePass(!seePass)}
									/>
								</div>
							</div>
							<div className='login__enter-btns'>
								<div className='login__remember'>
									{/*<input type='checkbox' />*/}
									{/*Запомнить*/}
								</div>
								<div className='login__forgot-pass'>
									{/*<Link to={'/'}>Забыли пароль ?</Link>*/}
								</div>
							</div>
							<div className='login__enter-button'>
								<input type='submit' value='Войти' />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
