import React, { type FC, PropsWithChildren } from 'react'
import { Provider as AlertProvider, positions, transitions } from 'react-alert'

import { AlertTemplate } from '../../components/alert-template'

import { QueryClientProvider } from './QueryClientProvider'

const optionsAlert = {
	position: positions.BOTTOM_RIGHT,
	timeout: 7000, // 5000,
	offset: '30px',
	transition: transitions.SCALE,
	containerStyle: { zIndex: 10000 }
}

export const MainProviders: FC<PropsWithChildren> = ({ children }) => {
	return (
			<AlertProvider template={AlertTemplate} {...optionsAlert}>
				<QueryClientProvider>{children}</QueryClientProvider>
			</AlertProvider>
	)
}

