import { Button, List, ListItem, Radio, RadioGroup } from '@mui/joy'
import {
	Box,
	FormControl,
	FormControlLabel,
	Modal,
	TextField
} from '@mui/material'
import ModalWindow from 'elements/add-account/typing'
import { IAgens } from 'pages/agents'
import React, { FC, useEffect, useState } from 'react'

import './agentsuser.scss'
import closedVector from './assets/closed.webp'

interface IProps extends ModalWindow.ModalWindowProps {
	data?: IAgens
	loading: boolean
	onSubmit: (data: IAgens) => void
}

export const AgentsUserModal: FC<IProps> = ({
	isOpen,
	setIsOpen,
	data,
	onSubmit
}) => {
	const [formData, setFormData] = useState<IAgens>({
		epos: '',
		epos__id: undefined,
		reddy_id: '',
		is_blocked: false,
		mobcash_login: ''
	})

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = event.target

		setFormData({
			...formData,
			[name]: value
		})
	}

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({
			...formData,
			is_blocked: event.target.value === 'true' ? true : false
		})
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		onSubmit(formData)
	}

	useEffect(() => {
		if (data && isOpen) {
			setFormData({
				mobcash_login: data.mobcash_login,
				epos: data.epos,
				epos__id: data.epos__id,
				reddy_id: data.reddy_id,
				is_blocked: data.is_blocked,
				id: data.id
			})
		} else {
			setFormData({
				mobcash_login: undefined,
				epos: undefined,
				epos__id: undefined,
				reddy_id: undefined,
				is_blocked: undefined,
				id: undefined
			})
		}
	}, [data, isOpen])

	const title = data ? 'Редактировать агента' : 'Добавить агента'
	const buttonText = data ? 'Обновить агента' : 'Добавить агента'
	const description = data
		? 'Введите данные, чтобы обновить текущего агента'
		: '	Введите данные, чтобы добавить нового агента'

	return (
		<Modal open={isOpen} onClose={() => setIsOpen(false)}>
			<div className='agents-users'>
				<div className='agents-users__content'>
					<div
						className='agents-users__closed'
						onClick={() => setIsOpen(!isOpen)}
					>
						<img src={closedVector} alt='closaed' />
					</div>
					<Box
						sx={{
							flexDirection: 'column',
							gap: '20px',
							display: 'flex',
							textAlign: 'center'
						}}
					>
						<Box sx={{ flexDirection: 'column', gap: 1, display: 'flex' }}>
							<h3 style={{ fontSize: '40px', margin: 0 }}>{title}</h3>
							<p style={{ color: '#6F6F82', margin: 0 }}>{description}</p>
						</Box>
						<form onSubmit={handleSubmit} className='agents-users__form'>
							<Box sx={{ flexDirection: 'column', gap: 3, display: 'flex' }}>
								<FormControl fullWidth>
									<TextField
										required
										name='epos'
										id='outlined-required'
										label='Epos'
										value={formData.epos}
										InputLabelProps={{
											shrink: true
										}}
										onChange={handleInputChange}
									/>
								</FormControl>
								<FormControl fullWidth>
									<TextField
										required
										name='epos__id'
										id='outlined-required'
										label='Epos ID'
										value={formData.epos__id}
										InputLabelProps={{
											shrink: true
										}}
										onChange={handleInputChange}
									/>
								</FormControl>

								<FormControl fullWidth>
									<TextField
										name='reddy_id'
										id='outlined-required'
										label='Reddy ID'
										value={formData.reddy_id}
										InputLabelProps={{
											shrink: true
										}}
										onChange={handleInputChange}
									/>
								</FormControl>

								<FormControl fullWidth>
									<TextField
										name='mobcash_login'
										id='outlined-required'
										label='Teamcash login'
										InputLabelProps={{
											shrink: true
										}}
										value={formData.mobcash_login}
										onChange={handleInputChange}
									/>
								</FormControl>
								<Box className={'agents-users__radio-group-box'}>
									<span>Teamcash status</span>
									<RadioGroup
										aria-labelledby='demo-controlled-radio-buttons-group'
										name='controlled-radio-buttons-group'
										value={formData.is_blocked ? 'true' : 'false'}
										title='Teamcash status'
										lang='ru'
										className={'agents-users__radio-group'}
										onChange={handleRadioChange}
									>
										<List
											sx={{
												minWidth: 240,
												display: 'flex',
												flexDirection: 'row',
												gap: '10px',
												'--List-gap': '0.5rem',
												'--ListItem-paddingY': '1rem',
												'--ListItem-radius': '8px',
												'--ListItem-flex': '1',
												'--ListItemDecorator-size': '32px'
											}}
										>
											<ListItem variant='outlined' sx={{ boxShadow: 'sm' }}>
												<FormControlLabel
													control={
														<Radio
															overlay
															value={'true'}
															sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
															slotProps={{
																action: ({ checked }) => ({
																	sx: theme => ({
																		...(checked && {
																			inset: -1,
																			border: '2px solid',
																			borderColor:
																				theme.vars.palette.primary[500]
																		})
																	})
																})
															}}
														/>
													}
													label='Blocked'
													value='true'
												/>
											</ListItem>
											<ListItem variant='outlined' sx={{ boxShadow: 'sm' }}>
												<FormControlLabel
													control={
														<Radio
															overlay
															value='false'
															sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
															slotProps={{
																action: ({ checked }) => ({
																	sx: theme => ({
																		...(checked && {
																			inset: -1,
																			border: '2px solid',
																			borderColor:
																				theme.vars.palette.primary[500]
																		})
																	})
																})
															}}
														/>
													}
													label='Not blocked'
													value='false'
												/>
											</ListItem>
										</List>
									</RadioGroup>
								</Box>

								<Button
									style={{ background: '#242BD7', padding: '24px' }}
									type='submit'
								>
									{buttonText}
								</Button>
							</Box>
						</form>
					</Box>
				</div>
			</div>
		</Modal>
	)
}
