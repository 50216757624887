import Cookies from "js-cookie"
import Login from "pages/login/Login"
import React from "react"
import { PropsWithChildren } from "react"

export const ProtectedRoute: React.FC<PropsWithChildren> = props => {
	const { children } = props

	const refresh = Cookies.get('refreshToken')

	// Проверка авторизации
	if (!refresh) {
		return <Login />
	}

	return <>{children}</>
}