import React, { FC, FormEvent } from 'react';
import './ticketsFilters.scss'
import ModalWindow from '../add-account/typing';
import closedVector from './assets/closed.webp'
import TicketsTypes from "../../app/api/tickets/tickets.types";

const TicketsFilters: FC<ModalWindow.ModalWindowProps> = ({
        isOpen,
        setIsOpen,
        valuesOfSelectedFilters,
        setValuesOfSelectedFilters
    }) => {

    const useTicketsFilters = (event: FormEvent) => {
        event.preventDefault();

        const valuesFilters: string[] = [];

        (event.target as HTMLFormElement)
            .querySelectorAll('input, select')
            .forEach(
                (element) => {
                    if(setValuesOfSelectedFilters !== undefined) {
                        setValuesOfSelectedFilters((state: TicketsTypes.Data) => {
                            // @ts-ignore
                            if(element.value) {
                                if(element.tagName === "SELECT"){
                                    // @ts-ignore
                                    if(element.value !== "All") {
                                        // @ts-ignore
                                        state[element.name] = element.value
                                    } else {
                                        // @ts-ignore
                                        state[element.name] = undefined
                                    }
                                } else {
                                    // @ts-ignore
                                    state[element.name] = element.value
                                }
                            } else {
                                // @ts-ignore
                                state[element.name] = undefined
                            }

                            return {...state}
                        })
                    }
                })
        setIsOpen(!isOpen)
    }

    return (
    <div className="tickets-filters-window">
        <div className="tickets-filters">
            <div className="tickets-filters__content">
                <div className="tickets-filters__closed" onClick={() => setIsOpen(!isOpen)}><img src={closedVector} alt='closaed'/></div>
                <form onSubmit={useTicketsFilters}>
                <div className="tickets-filters__blocks">
                    <div className="tickets-filters__top">
                        <div className="tickets-filters__block-item">
                            <label>Reddy id</label>
                            <input defaultValue={valuesOfSelectedFilters?.reddy_id} name="reddy_id" type='number' placeholder='Entry id...' />
                        </div>
                        <div className="tickets-filters__block-item">
                            <label>Epos</label>
                            <input defaultValue={valuesOfSelectedFilters?.epos} name='epos' type='text' placeholder='Entry login...' />
                        </div>
                        <div className="tickets-filters__block-item">
                            <label>Epos id</label>
                            <input defaultValue={valuesOfSelectedFilters?.epos_id} name="epos_id" type='number' placeholder='Entry id...' />
                        </div>
                        <div className="tickets-filters__block-item">
                            <label>MobCash login</label>
                            <input defaultValue={valuesOfSelectedFilters?.mobcash_login} name="mobcash_login" type='text' placeholder='Entry login...' />
                        </div>
                    </div>
                    <div className="tickets-filters__middle">
                        <div className="tickets-filters__block-item" style={{width: "285px"}}>
                            <label>Problem</label>
                            <select defaultValue={valuesOfSelectedFilters?.problem} name="problem" placeholder='Entry id...'>
                                <option>All</option>
                                <option>I can't log in to MobCash</option>
                                <option>My cash does not show up on the Melbet site</option>
                            </select>
                        </div>
                        <div className="tickets-filters__block-item">
                        <label>Status</label>
                            <select defaultValue={valuesOfSelectedFilters?.status} name="status" placeholder='Choose...'>
                                <option>All</option>
                                <option>In processing</option>
                                <option>Solved</option>
                            </select>
                        </div>
                        <div className="tickets-filters__block-item">
                        <label>Time</label>
                            <input defaultValue={valuesOfSelectedFilters?.created_at?.toString()} name="created_at" placeholder='Choose...'/>
                        </div>
                    </div>
                </div>
                <button className="tickets-filters__btn" type='submit'>
                    Apply filters
                </button>
                </form>
            </div>
        </div>
    </div>
    )
};

export default TicketsFilters;
